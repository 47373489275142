@import '../../styles/variables';
@import '../../styles/mixins';

.currentAccountContainer {
  margin-left: 20px;
  margin-right: 20px;
  background-color: rgba(145, 158, 171, 0.12);
  border-radius: 12px;
  height: 76px;
  display: flex;
  align-items: center;
  padding: 16px 20px;
  cursor: pointer;

  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    text-align: center;
    border-radius: 50%;
    margin-right: 16px;
  }

  .content {
    display: flex;
    flex-direction: column;

    .title {
      font-family: $jost;
      font-weight: 600;
      font-size: 14px;
    }

    .companyName {
      font-family: $jost;
      font-weight: 600;
      font-size: 14px;
      margin-top: 4px;
    }

    .subTitle {
      font-family: $jost;
      font-weight: 500;
      font-size: 12px;
      color: $primary-light;
      margin-top: 4px;
    }
  }
}
